import React, { createContext, useContext, useEffect, useState } from 'react';
import { supabase } from '../client'; // Ensure correct path
import { jwtDecode } from 'jwt-decode'

// Create the context
const AuthContext = createContext();

// Create a provider component
export function AuthProvider({ children }) {
    const [authenticated, setAuthenticated] = useState(false);
    const [userRole, setRole] = useState(null);

    useEffect(() => {
        const { data: subscription  } = supabase.auth.onAuthStateChange(async (event, session) => {
            if (session) {
              const jwt = jwtDecode(session.access_token)
              const userRole = jwt.role
              setRole(userRole);
              setAuthenticated(true);
            } else {
                setAuthenticated(false);
                setRole("anon");
            }
        })
        return () => subscription.unsubscribe();
    }, []);

    console.log("AuthContext initialized with authenticated:", authenticated, "role:", userRole);

    return (
        <AuthContext.Provider value={{ authenticated, userRole }}>
            {children}
        </AuthContext.Provider>
    );
}

// Create a custom hook to use the AuthContext
export function useAuth() {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}

