import './style/Footer.css'
import { useNavigate } from 'react-router-dom';

export default function Footer() {
    const navigate = useNavigate();
    function handleNavigator(e, path) {
        e.preventDefault()
        navigate(path)
    }
    return <>
        <div class="grid grid-cols-4 gap-1 pt-6">
            <div className='pl-12'>
                <h2 class="uppercase font-semibold">contact us</h2>
                <ul class="max-w-md space-y-1 text-gray-500 list-none list-inside dark:text-gray-400">
                    <li>
                        Address: Poststraat 3 B 48 6135KR Sittard
                    </li>
                    <li>
                        Phone: +31 613317199
                    </li>
                    <li>
                        {/* Email: Customerservice@alyi.com */}
                    </li>
                </ul>
            </div>
            <div className='pl-12'>
                <h2 class="uppercase font-semibold">company</h2>
                <ul class="max-w-md space-y-1 text-gray-500 list-none list-inside dark:text-gray-400">
                    <li className='cursor-pointer pt-1' onClick={e => handleNavigator(e, "/about")} >
                        About
                    </li>
                    <li className='cursor-pointer pt-1' onClick={e => handleNavigator(e, "/services")} >
                        Services
                    </li>
                    <li className='cursor-pointer pt-1' onClick={e => handleNavigator(e, "/support")}>
                        Support
                    </li>
                </ul>
            </div>
            <div className='pl-12'>
                <h2 class="uppercase font-semibold">customers</h2>
                <ul class="max-w-md space-y-1 text-gray-500 list-none list-inside dark:text-gray-400">
                    <li className='cursor-pointer pt-1' onClick={e => handleNavigator(e, "/faq")} >
                        FAQ
                    </li>
                    <li className='cursor-pointer pt-1' onClick={e => handleNavigator(e, "/termsofservice")} >
                        Terms of service
                    </li>
                    <li className='cursor-pointer pt-1' onClick={e => handleNavigator(e, "/privacy")}>
                        Privacy
                    </li>
                </ul>
            </div>
            <div>
                <h2 class="uppercase text-center font-semibold">connect with us</h2>
                <div class="flex flex-wrap justify-center pt-3">
                    <div class="flex me-2">
                        <a href='https://www.x.com/'>
                            <i class="fa-brands fa-x-twitter fa-xl px-1"></i>
                        </a>
                        <a href='https://www.whatsapp.com/'>
                            <i class="fa-brands fa-whatsapp fa-xl px-1"></i>
                        </a>
                        <a href='https://www.linkedin.com/'>
                            <i class="fa-brands fa-linkedin fa-xl px-2"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </>
}