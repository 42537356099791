import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import AssistantPage from "./pages/Client/AssistantPage";
import AssistantsPage from "./pages/Admin/AssistantsPage";
import ErrorPage from "./pages/ErrorPage";
import ServicesPage from "./pages/ServicesPage";
import AboutPage from "./pages/AboutPage";
import LoginPage from "./pages/Auth/LoginPage.jsx";
import Navbar from "./components/navigation/Navbar.jsx";
import Footer from "./components/navigation/Footer";
import SupportPage from "./pages/Support/SupportPage.jsx";
import FAQPage from "./pages/Support/FAQPage.jsx";
import TermsOfServicePage from "./pages/Support/TermsOfServicePage.jsx";
import PrivacyPage from "./pages/Support/PrivacyPage.jsx";
import NotFoundPage from "./pages/NotFoundPage.jsx";
import { Navigate } from "react-router-dom";
import { useAuth, AuthProvider } from "./lib/AuthContext.jsx";


function RedirectAuthenticated({ children }) {
    const { authenticated } = useAuth();

    return authenticated ? <Navigate to="/" /> : children;
}

function RedirectUnAuthenticated({ children }) {
    const { authenticated } = useAuth();

    return authenticated ?  children : <Navigate to="/" />;
}

function AdminPage({ children }) {
    const { userRole } = useAuth();

    if (userRole === null) {
        return <div>Loading...</div>; // Show a loading state while fetching the role
    }

    return userRole !== 'admin' ? <Navigate to="/" /> : children;
}

function App() {
    return (
        <AuthProvider>
            <BrowserRouter>
                <Navbar />
                <Routes>
                    <Route path="" element={<LandingPage />} />
                    <Route
                        path="assistants"
                        element={
                            <AdminPage>
                                <AssistantsPage />
                            </AdminPage>
                        }
                    />
                    <Route
                        path="assistant/me"
                        element={
                            <RedirectUnAuthenticated>
                                <AssistantPage />
                            </RedirectUnAuthenticated>
                        }
                    />
                    <Route
                        path="assistant"
                        element={
                            <RedirectUnAuthenticated>
                                <AssistantPage />
                            </RedirectUnAuthenticated>
                        }
                    />
                    <Route path="services" element={<ServicesPage />} />
                    <Route path="about" element={<AboutPage />} />
                    <Route
                        path="login"
                        element={
                            <RedirectAuthenticated>
                                <LoginPage />
                            </RedirectAuthenticated>
                        }
                    />

                    <Route path="error" element={<ErrorPage />} />
                    <Route path="support" element={<SupportPage />} />
                    <Route path="faq" element={<FAQPage />} />
                    <Route path="termsofservice" element={<TermsOfServicePage />} />
                    <Route path="privacy" element={<PrivacyPage />} />
                    <Route path="404" element={<NotFoundPage />} />
                </Routes>
                <Footer />
            </BrowserRouter>
        </AuthProvider> 
    );
}


export default App;
