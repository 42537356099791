import SignUpForNewsletterComponent from '../components/SignUpForNewsletterComponent'
import './style/landingPage.css'
import { useNavigate } from 'react-router-dom';

function LandingPage() {
    
    const navigate = useNavigate();

    function handleNavigator(e, path) {
        e.preventDefault()
        navigate(path)
    }
    return <>
        <section id="get-started">
            <div className='max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4 py-10'>
                <div>
                    <p className='text-left'>Unlock a world of knowledge at your fingertips. ALyi is your personal assistant,
                        expertly trained to provide accurate and instant answers to all your common
                        questions. Whether you're looking for quick solutions or detailed explanations,
                        ALyi is here.
                    </p>
                </div>
                <div className='py-8'>
                    <button type="button" onClick={e => handleNavigator(e, "/services")} class="focus:outline-none text-white  text-lg  font-medium rounded-xl  px-8 py-2 text-center dark:bg-white-600 dark:hover:bg-darkblue-700">Get started</button>
                </div>
            </div>
        </section>

        <div class="whitespace-normal hover:whitespace-pre">
            <br />
        </div>

        <SignUpForNewsletterComponent />
    </>
}
export default LandingPage
