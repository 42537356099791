import { useEffect, useState } from "react";
import FacebookSignupButton from "../../components/FacebookSignupButton";
import { getMyAssistant } from "../../services/assistantService";
import { useNavigate } from "react-router-dom";
import '../style/AssistantPage.css'

function AssistantPage() {
    const navigate = useNavigate();

    const [assistant, setAssistant] = useState()

    //Fetching the current user assistant 
    const fetchAssistant = async () => {
        try {
            const res = await getMyAssistant();
            if (res?.data) {
                const fetchedAsssitant = {
                    "id": res?.data.id,
                    "questions": res?.data.questions,
                    "accountsIdentifiers": res?.data.accountsIdentifiers.map(ai => ai.phoneNumber),
                    "status": res?.data.status,
                    "costs": res?.data.costs,
                    "features": res?.data.features,
                    "updatedAt": `${new Date(res?.data.updatedAt).toLocaleTimeString('en-GB', {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true // Use 24-hour format
                    })} 
${new Date(res?.data.updatedAt).toLocaleDateString('en-GB').split('/').join('/')}`,
                    "createdAt": `${new Date(res?.data.createdAt).toLocaleTimeString('en-GB', {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true // Use 24-hour format
                    })} 
${new Date(res?.data.createdAt).toLocaleDateString('en-GB').split('/').join('/')}`
                }
                setAssistant(fetchedAsssitant);
            }
        } catch (error) {
            if (error.status === 404) {
                setAssistant(null);
                return
            }
            if (error.code === 'ECONNABORTED') {
                console.error('Request timed out:', error.message);
                navigate("/error");
                return
            }

            console.error(error);
            navigate("/error");
            return
        }
    };

    useEffect(() => {
        fetchAssistant();
    }, [navigate]);
    const statusElement = assistant?.status === "activated" ? <>
        <div>
            Status: {assistant.status} <i class="fa-regular fa-circle-check" style={{color:"lightGreen"}}></i>
        </div>
    </>
        :
        <>
                <div>
            Status: {assistant?.status} 
        </div>
        </>
    if (assistant === undefined) {
        return <>
            Loading..
        </>
    } else {
        return <>
            <section id="myAssistant">

                <div className="px-36 py-20">
                    <div></div>
                    <div className="text-lg col-span-3 border-solid border-2 border-transparent box text-white grid grid-cols-5 py-4">

                        <div className="flex items-center justify-center m-6 rounded-lg p-10" id="botIcon">
                            <i class="fa-solid fa-robot fa-4x"></i>
                        </div>

                        <div className="grid grid-cols-1 col-span-3">
                            <div className="grid  grid-cols-2 justify-around">
                                {statusElement}
                                <div>
                                    Last update: {assistant.updatedAt}
                                </div>
                            </div>
                            <div className="grid  grid-cols-2 justify-around">
                                <div>
                                    Cost: {assistant.costs}$
                                </div>
                                <div>
                                    Created at: {assistant.createdAt}
                                </div>
                            </div>
                            <div className="grid  grid-cols-2 justify-around">
                                <div>
                                    Features: {assistant?.features.join(',')}
                                </div>
                                <div>
                                    Connected to: {assistant.accountsIdentifiers.join(',')}
                                </div>
                            </div>
                        </div>

                        <div className="flex items-center justify-end pr-2 pb-28">
                            <i class="fa-regular fa-trash-can px-2 fa-xl" style={{color:"black"}}></i>
                            <i class="fa-regular fa-pen-to-square px-2 fa-xl" style={{color:"black"}}></i>
                        </div>
                    </div>
                </div>
                <div>
                </div>
            </section>

        </>
    }
}

export default AssistantPage;

