import axios from "axios";

const header = () => {
  if (document.cookie.split('=')[1] !== null) {
    return {
      timeout: process.env.REACT_APP_REQUEST_TIMEOUT,
      headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI0NDI1YTFkNy1mMjk2LTQwNGMtOWRlMS04OWYzMzEyMDAxOTMiLCJpYXQiOjE2OTQ0ODgzMzAsImV4cCI6MTY5NDQ5MTkzMCwicm9sZXMiOlsib3duZXIiLCJ1c2VyIl19.1oeNh3MY3Hg8ZkZ_RbKf8fs8xgOwJhv0BSFV6cT7a1k"//document.cookie.split('=')[1]
      }
    }
  }
  else
    return {
      "Content-type": "application/json"
    }
}

export const base = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  ...header()
}
);

