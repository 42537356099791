// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#get-started {
    background: linear-gradient(0.25turn, #014A7F, #1BD2FE);
}

#signup-newsletter {
    background: linear-gradient(0.25turn, #014A7F, #1BD2FE);
}

.dark\\:hover\\:bg-darkblue-700 {
    background-color: #00407C;
}`, "",{"version":3,"sources":["webpack://./src/pages/style/landingPage.css"],"names":[],"mappings":"AAAA;IACI,uDAAuD;AAC3D;;AAEA;IACI,uDAAuD;AAC3D;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["#get-started {\n    background: linear-gradient(0.25turn, #014A7F, #1BD2FE);\n}\n\n#signup-newsletter {\n    background: linear-gradient(0.25turn, #014A7F, #1BD2FE);\n}\n\n.dark\\:hover\\:bg-darkblue-700 {\n    background-color: #00407C;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
