import "./style/Navbar.css";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../lib/AuthContext";
import { supabase } from '../../client'; 

export default function Navbar() {
    const { authenticated } = useAuth();
    const navigate = useNavigate();

    function handleNavigator(e, path) {
        e.preventDefault();
        navigate(path);
    }

    async function handleLogout(e) {
        e.preventDefault();
        const { error } = await supabase.auth.signOut();
        if (!error) {
            navigate("/login");
        } else {
            console.error("Error logging out:", error);
        }
    }

    return (
        <nav className="border-gray-200">
            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                <a onClick={e => handleNavigator(e, "/")} href=" " className="flex items-center space-x-3 rtl:space-x-reverse">
                    <img src="alyi-logo.png" className="h-8" alt="Alyi Logo" />
                </a>
                <button data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
                    <span className="sr-only">Open main menu</span>
                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
                    </svg>
                </button>
                <div className="hidden w-full md:block md:w-auto" id="navbar-default">
                    <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border rounded-lg  md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0">
                        <li>
                            <a onClick={e => handleNavigator(e, "/")} href=" " className="text-lg block py-2 px-3 text-gray-900 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-black-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Home</a>
                        </li>
                        <li>
                            <a onClick={e => handleNavigator(e, "/assistant")} href=" " className="text-lg block py-2 px-3 text-gray-900  hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-black-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Assistant</a>
                        </li>
                        <li>
                            <a onClick={e => handleNavigator(e, "/services")} href=" " className="text-lg block py-2 px-3 text-gray-900  hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-black-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Services</a>
                        </li>
                        <li>
                            <a onClick={e => handleNavigator(e, "/about")} href=" " className="text-lg block py-2 px-3 text-gray-900  hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-black-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">About</a>
                        </li>
                    </ul>
                </div>
                {authenticated ? (
                    <button type="button" onClick={handleLogout} className="focus:outline-none font-medium rounded-xl text-sm px-8 py-1 text-center dark:bg-white-600 dark:hover:bg-white-700">
                        Logout
                    </button>
                ) : (
                    <button type="button" onClick={e => handleNavigator(e, "/login")} className="focus:outline-none font-medium rounded-xl text-sm px-8 py-1 text-center dark:bg-white-600 dark:hover:bg-white-700">
                        Login
                    </button>
                )}
            </div>
        </nav>

    );
}
