import { supabase } from "../../client";

export default function LoginPage() {
    
    const handleGoogleLogin = async () => {
        const { user, error } = await supabase.auth.signInWithOAuth({
          provider: 'google',
        });
        if (error) {
          console.error('Error logging in with Google:', error);
        } else {
          console.log('Logged in user:', user);
        }
      };

    return <>

<div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-sm">
        <h1 className="text-2xl font-semibold text-center mb-6">Login</h1>
        <button
          onClick={handleGoogleLogin}
          className="flex items-center justify-center w-full p-3 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
        >
          <svg
            className="w-6 h-6 mr-2"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M21.35 11.1h-9.18v2.67h5.9c-.36 2-2.18 3.3-5.9 3.3-3.59 0-6.53-2.95-6.53-6.53s2.95-6.53 6.53-6.53c1.66 0 3.07.58 4.22 1.53l1.89-1.89c-1.63-1.5-3.73-2.42-6.1-2.42C5.5 1.33 1.33 5.5 1.33 11S5.5 20.67 11 20.67c5.67 0 9.5-3.95 9.5-9.56 0-.65-.07-1.31-.15-1.95z" />
          </svg>
          Login with Google
        </button>
      </div>
    </div>
    </>
}
