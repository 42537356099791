// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dark\\:text-black-500 {
    color: rgb(0, 0, 0);
}
.dark\\:bg-darkblue-700{
    background-color: #00407C;
}`, "",{"version":3,"sources":["webpack://./src/components/style/SignUpForNewsletterComponent.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;AACA;IACI,yBAAyB;AAC7B","sourcesContent":[".dark\\:text-black-500 {\n    color: rgb(0, 0, 0);\n}\n.dark\\:bg-darkblue-700{\n    background-color: #00407C;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
