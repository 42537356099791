// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#myAssistant{
    background: linear-gradient(0.25turn, #014A7F, #1BD2FE);
}

.box{
    background-color: #00407c62;
}

#botIcon{
    background-color: #0A66C2;
}`, "",{"version":3,"sources":["webpack://./src/pages/style/AssistantPage.css"],"names":[],"mappings":"AAAA;IACI,uDAAuD;AAC3D;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["#myAssistant{\n    background: linear-gradient(0.25turn, #014A7F, #1BD2FE);\n}\n\n.box{\n    background-color: #00407c62;\n}\n\n#botIcon{\n    background-color: #0A66C2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
