import { useNavigate } from "react-router-dom"
import { subscribeToPlatformAccount } from "../services/metaService"


export default function SubscribeToPlatformAccount() {
    const navigate = useNavigate()

    async function LinkAccountToPlatform() {
        const request = {
            platformId: "12121",
            platformType: "Whatsapp"
        }
        await subscribeToPlatformAccount(request).then((response) => {
            if (response.status === 201)
                navigate("/")
        }).catch(() => {
            navigate("/error")
        })
    }

    return <>
        <button onClick={LinkAccountToPlatform}>
            Link
        </button>
    </>
}
