/* global FB */
import { useEffect } from "react";
import { generateToken } from "../services/metaService"
import { useNavigate } from 'react-router-dom';

function FacebookSignupButton() {
    const navigate = useNavigate()
    useEffect(() => {
        // JavaScript SDK configuration and setup
        window.fbAsyncInit = function () {
            FB.init({
                appId: process.env.REACT_APP_FACEBOOK_APP_ID,
                xfbml: true,
                version: 'v20.0'
            });
        };

        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }, []);

    // Facebook Login with JavaScript SDK
    const launchWhatsAppSignup = () => {
        if (typeof FB !== 'undefined') {
            // Conversion tracking code
            if (window.fbq) {
                window.fbq('trackCustom', 'WhatsAppOnboardingStart', { appId: process.env.REACT_APP_FACEBOOK_APP_ID, feature: 'whatsapp_embedded_signup' });
            }
            // Launch Facebook login
            FB.login(function (response) {
                if (response.authResponse) {
debugger
                    const request = {
                        credentials: {
                            code: response.authResponse.accessToken
                        },
                        type: "facebook"
                    }
                    generateToken(request)
                        .then(() => {
                            navigate("/assistants")
                            return
                        })
                        .catch(() => {
                            alert("Something went wrong please try again.")
                            return
                        })
                    // The returned code must be transmitted to your backend, 
                    // which will perform a server-to-server call from there to our servers for an access token
                } else {
                    console.log('User cancelled login or did not fully authorize.');
                }
            }, {
                "scope": "business_management, whatsapp_business_management, whatsapp_business_messaging",
                "extras": {
                  "feature": "whatsapp_embedded_signup",
                  "version": 1,
                  "setup": {
                  }
                }
              });
        } else {
            console.log('Facebook SDK not loaded yet.');
        }
    };

    return (
        <button onClick={launchWhatsAppSignup}>
            Launch WhatsApp Signup
        </button>
    );
}
export default FacebookSignupButton