// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nav {
    background: linear-gradient(0.25turn, #014A7F, #1BD2FE);
}

.md\\:dark\\:hover\\:text-black-500:hover {
    color: rgb(0 0 0);
}

.dark\\:hover\\:bg-white-700:hover {
    background-color: rgb(229 228 228);
}

.dark\\:hover\\:bg-white-700 {
    background-color: rgb(255, 255, 255);
}`, "",{"version":3,"sources":["webpack://./src/components/navigation/style/Navbar.css"],"names":[],"mappings":"AAAA;IACI,uDAAuD;AAC3D;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,oCAAoC;AACxC","sourcesContent":["nav {\n    background: linear-gradient(0.25turn, #014A7F, #1BD2FE);\n}\n\n.md\\:dark\\:hover\\:text-black-500:hover {\n    color: rgb(0 0 0);\n}\n\n.dark\\:hover\\:bg-white-700:hover {\n    background-color: rgb(229 228 228);\n}\n\n.dark\\:hover\\:bg-white-700 {\n    background-color: rgb(255, 255, 255);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
