import { useNavigate } from 'react-router-dom';
import './style/SignUpForNewsletterComponent.css'
export default function SignUpForNewsletterComponent() {
    const navigate = useNavigate();

    function handleNavigator(e, path) {
        e.preventDefault()
        navigate(path)
    }

    return <>
        <section className="text-center p-2" id='signup-newsletter'>
            <h3 className="text-2xl text-white">Sign up for our newsletter</h3>
            <span className="text-white text-sm">Sign up to receive our latest plugin news and tutorials by email</span>
            <div className="flex justify-center py-4">
                <div className="mx-4">
                    <input type="text" id="helper-text" aria-describedby="helper-text-explanation" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-darkblue-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Your name" />
                </div>

                <div className="mx-4">
                    <input type="email" id="helper-text" aria-describedby="helper-text-explanation" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-darkblue-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Your email" />
                    <p id="helper-text-explanation" class="mt-2 text-sm  text-gray-500 text-white">We’ll never share your details. Read our <a onClick={e => handleNavigator(e, "/privacy")} href=" " class="font-medium  hover:underline dark:text-black-500">Privacy Policy</a>.</p>
                </div>

                <div className="pt-2">
                    <button type="button" class="focus:outline-none font-medium rounded-xl text-sm p px-8 py-1 text-center dark:bg-white-600 dark:hover:bg-white-700">SIGN UP</button>
                </div>
            </div>
        </section>
    </>
}